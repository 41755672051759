.hero-section_text {
  h4 {
    background: linear-gradient(
      91deg,
      #5d3fd3 -1.62%,
      #f9b16e 30.27%,
      #f47f30 68.76%
    );
    background-clip: content-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Clash Display, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
  }

  p {
    // color: #fff;
    // font-family: Clash Grotesk;
    // font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.card {
  &-content {
    font-family: ClashDisplay, sans-serif;
  }
}

.hero {
  overflow: hidden;
  border-radius: 0 0 2rem 2rem;
}
