.faq {
  &-card {
    border-radius: 20px;
    background: #fafafa;
    padding: 2rem;
    margin: auto;
  }

  .chakra-accordion__item {
    border: none;
    padding: 0.5rem;
    color: #1d1d1f;
  }
}
