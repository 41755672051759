.banner {
  &-img {
    // background: url("../assets/Free\ Hand\ Holding\ iPhone\ 12\ Mini\ 1.png")
    //   center no-repeat;
    // background-size: contain;
    margin: auto;
    img {
      width: 100%;
      height: 20rem;
      object-fit: cover;
    }
  }
}
