@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Schibsted+Grotesk:wght@400;500;600;700&display=swap");
@import url("https://api.fontshare.com/v2/css?f[]=clash-display@200,400,700,500,600,300&f[]=clash-grotesk@400,600,300,500&display=swap");

// style imports
@import "./hero";
@import "./banner";
@import "./clash-display";
@import "./faq";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-family: "Inter", sans-serif;
  // font-family: "Schibsted Grotesk", sans-serif;
  font-family: "Clash Display", sans-serif;
}

.container {
  margin: auto;
}

.footer {
  border-radius: 44px 44px 0px 0px;
  background: #121212;
}
